import React from 'react';

export default function YTVideo(props)
{
    if (props.videoURL == undefined) return <div></div>;

    const urlObj = new URL(props.videoURL);
    const params = new URLSearchParams(urlObj.search);
    const autoplay = props.autoplay ? '1' : '0';
    const controls = props.controls ? '1' : '0';
    const mute = props.mute ? '1' : '0';
    const loop = props.loop ? '1' : '0';
    const srcValue = "https://www.youtube.com/embed/" + params.get("v") + "?autoplay=" + autoplay + "&mute=" + mute + "&controls=" + controls + "&loop=" + loop + "&rel=0&showinfo=0&modestbranding=1&cc_load_policy=1&cc_lang_pref=en";
    const height = props.height ? '500' : '300';

    return (
        <div>
            <iframe title={props.videoURL} width="100%" height={height} style={{ border: "none", zIndex: 1200, position: 'relative' }}
                src={srcValue}>
            </iframe>
        </div>
    );
}