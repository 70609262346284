import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { AppBar, Backdrop, Button, Container, Grid, IconButton, Stack, Typography, Divider, Paper } from '@mui/material';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import relativeTime from 'dayjs/plugin/relativeTime';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { default as React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fs_createReport, fs_loadReport, fs_loadSessions, fs_loadSessionsBetweenDates, fs_updateProperty } from '../../app/firestore';
import Spacer from '../common/Spacer';
import LoadingScreen from '../dialog/LoadingScreen';
import { withAuthentication } from '../login/withAuthentication';
import { getPreprompt, getUser, getWeeklyReport } from '../user/UserSlice';
import SessionsProgressBar from './SessionsProgressBar';
import { ai_getWeeklyReport } from '../../app/openai';
import ActionCheckBox from '../olivya/ActionCheckBox';
import { useParams } from "react-router-dom";
import ActionsProgressBar from './ActionsProgressBar';

function Report(props)
{
    dayjs.extend(relativeTime);
    dayjs.extend(advancedFormat);
    dayjs.extend(weekOfYear);
    dayjs.extend(isoWeek);

    const navigate = useNavigate();
    const user = useSelector(getUser);
    //const week = useSelector(getWeeklyReport);
    const { week } = useParams(); // Use URL params so users can open the report directly from a URL (eg. from an email)

    const [plannedActions, set_plannedActions] = React.useState(0);
    const [completedActions, set_completedActions] = React.useState(0);
    const [actionsRender, set_actionsRender] = React.useState(null);


    const [openBackdrop, setOpenBackdrop] = React.useState(false);
    const [report, setReport] = React.useState({
        startedSessions: 0,
        completedSessions: 0,
        emotions: [],
        sessions: [],
        summary: ""
    });

    const [reportSessions, setReportSessions] = React.useState([]);

    // Initialize an array for the days of the current week starting from Monday
    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const daysOfWeekShort = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
    
    const getWeekRange = (year, week) =>
    {
        // Create a dayjs object for the first day of the year
        const startOfYear = dayjs(`${year}-01-01`);

        // Get the start of the specified week
        const startOfWeek = startOfYear.isoWeek(week).startOf('isoWeek').subtract(1, 'day');

        // Get the end of the specified week
        const endOfWeek = startOfYear.isoWeek(week).endOf('isoWeek').subtract(1, 'day');

        return { startOfWeek, endOfWeek };
    };

    const { startOfWeek, endOfWeek } = getWeekRange(dayjs().format('YYYY'), week);

    useEffect(() =>
    {
        window.scrollTo(0, 0);

        setOpenBackdrop(true);
        fs_loadReport(endOfWeek.format(), onLoadReport);
        
        // cleanup function
        return () =>
        {
        };
    }, []);

    const onLoadReport = report =>
    {
        if (report)
        {
            setReport(report);
            fs_loadSessionsBetweenDates(startOfWeek.format(), endOfWeek.format(), sessions =>
            {
                setReportSessions(sessions);
                generateGoalsAndActions(sessions);
                setOpenBackdrop(false);
            });
        }
        else
        {
            fs_loadSessionsBetweenDates(startOfWeek.format(), endOfWeek.format(), onLoadSessions);
        }
    }

    const onLoadSessions = sessions =>
    {
        let _report =
        {
            startedSessions: 0,
            completedSessions: 0,
            emotions: [],
            sessions: [],
            summary: "",
            created: endOfWeek.format(),
            status: 'completed',
            userId: user.uid,
        }

        let longSumarries = [];

        for (var i = 0; i < sessions.length; i++)
        {
            let session = sessions[i];
            _report.startedSessions++;
            if (session.status === 'completed')
            {
                _report.completedSessions++;
                if( session.emotions !== undefined)
                    _report.emotions = _report.emotions.concat(session.emotions);
                
                longSumarries.push({ role: 'user', content: session.long_summary });
            }
        }

        _report.emotions = [...new Set(_report.emotions)];

        if (_report.completedSessions > 0)
            ai_getWeeklyReport(_report, longSumarries, onWeeklySummary);
        else
        {
            _report.summary = 'I didn\'t complete any sessions this week.';
            onWeeklySummary(_report);
        }

        setReportSessions(sessions);
        generateGoalsAndActions(sessions);
    }

    const onWeeklySummary = (_report) =>
    {
        fs_createReport(_report, () => setOpenBackdrop(false));
        setReport(_report);
    }

    const onActionsChange = (sessionId, index, isChecked) =>
    {
        let sessionActions = ["empty"];
        for (var i = 0; i < reportSessions.length; i++)
        {
            if (reportSessions[i].id === sessionId)
                sessionActions = reportSessions[i].actions;
        }

        let oldActions = structuredClone(sessionActions);
        //if actions are an array of strings, an old format.. then convert it to an array of objects!
        if (oldActions[0].isChecked === undefined)
        {
            let tempActions = [];
            for (var i = 0; i < oldActions.length; i++)
            {
                if (i === index)
                    tempActions.push({ title: oldActions[i], isChecked: true });
                else
                    tempActions.push({ title: oldActions[i], isChecked: false });
            }
            oldActions = tempActions;
        }

        oldActions[index].isChecked = isChecked;
        
        //update session in firestore
        fs_updateProperty(sessionId, 'actions', oldActions, () => { onLoadReport(report) });
    }

    const generateGoalsAndActions = (sessions) =>
    {
        let output = [];
        let groupedSessions = {};
        let ascSessions = [...sessions];
        ascSessions.reverse();

        for (var i = 0; i < ascSessions.length; i++)
        {
            let currentSession = ascSessions[i];
            let currentDay = dayjs(currentSession.created).format('dddd, MMMM D');
            if (groupedSessions[currentDay] === undefined)
                groupedSessions[currentDay] = [];

            groupedSessions[currentDay].push(currentSession);
        }

        let plannedActions = 0;
        let completedActions = 0;

        for (var day in groupedSessions)
        {
            let goalsList = [];
            let dailySessions = groupedSessions[day];
            for (var i = 0; i < dailySessions.length; i++)
            {
                let session = dailySessions[i];
                
                if ((session.goals !== undefined && session.goals.length > 0) || (session.actions !== undefined && session.actions.length > 0))
                { 
                    if(session.goals.length > 0)
                        goalsList.push(<Typography variant="long_session_summary_bold">- {session.goals.join('. ')}</Typography>);
                    else
                        goalsList.push(<Typography variant="long_session_summary">- I didn't set a concrete goal.</Typography>);

                    goalsList.push(<Typography variant="long_session_summary"> </Typography>);
                    if (session.actions.length > 0)
                    {
                        let newActions = [];
                        if (session.actions !== undefined && session.actions.length > 0)
                        {
                            newActions = session.actions;

                            let oldActions = session.actions;
                            //if actions are an array of strings, an old format.. then convert it to an array of objects!
                            if (oldActions[0].isChecked === undefined)
                            {
                                let tempActions = [];
                                for (var j = 0; j < oldActions.length; j++)
                                {
                                    tempActions.push({ title: oldActions[j], isChecked: false });
                                }
                                newActions = tempActions;
                            }
                        }

                        for (var j = 0; j < newActions.length; j++)
                        {
                            plannedActions++;
                            if (newActions[j].isChecked)
                                completedActions++;

                            goalsList.push(<ActionCheckBox
                                key={newActions[j].title}
                                size='large'
                                label={newActions[j].title}
                                sessionId={session.id}
                                index={j}
                                isChecked={newActions[j].isChecked}
                                onClick={onActionsChange} />);
                        }
                    }
                    else
                        goalsList.push(<Typography variant="long_session_summary">-> I didn't set a concrete action.</Typography>);

                    goalsList.push(<Typography variant="long_session_summary"> </Typography>);
                }
            }
            if (goalsList.length > 0)
            {
                let dayOfWeek = (
                <Stack key={"goal_" + day} spacing={1}>
                    <Typography variant="h5"><b>{day}.</b></Typography>
                    <Paper variant='report_goals'>
                        <Stack spacing={0}>
                            {goalsList}
                        </Stack>
                    </Paper>
                    <Divider variant="middle" component="span" sx={{paddingTop: '20px'} } />
                </Stack>);
                output.push(dayOfWeek);
            }
        }

        set_completedActions(completedActions);
        set_plannedActions(plannedActions);
        set_actionsRender(output);
    }

    const generateEmotions = () =>
    {
        if (report.emotions.length > 0)
            return <Typography variant="long_session_summary">This week I was feeling <b>{report.emotions.join(', ')}</b>.</Typography>
        else
            return <span />;
    }
    
    return (
        <Container disableGutters maxWidth="sm">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackdrop}
            >
                <LoadingScreen />
            </Backdrop>
            <AppBar position='relative' variant='blurry' sx={{ paddingLeft: '15px', paddingRight: '10px' }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <IconButton onClick={e => navigate("/", { replace: true })} >
                        <CloseRoundedIcon />
                    </IconButton>
                    <Typography variant="h2">Insights of Week #{dayjs(report.created).week()}</Typography>
                    <Spacer width={5} />
                </Grid>
                <Spacer height={10} />
                <ActionsProgressBar currentValue={completedActions} maxValue={plannedActions} />
                <Spacer height={10} />
                <SessionsProgressBar currentValue={report.completedSessions} maxValue={report.startedSessions} />
            </AppBar>
            <Stack sx={{ width: '100%', alignItems: 'center' }}>
                <Stack sx={{ width: '85%' }} spacing={2}>
                    <Typography variant="h3">Your Goals & Actions</Typography>
                    <Divider variant="middle" component="span" sx={{ paddingTop: '0px' }} />
                    {actionsRender}
                    <Typography variant="h3">Summary of the week</Typography>
                    <Typography variant="long_session_summary">{report.summary}</Typography>
                    {generateEmotions()}
                    <Spacer height={20} />
                    <Button
                        onClick={() => window.open("https://olivya.ai/coaches/", "_blank")}
                        variant="bright"
                        endIcon={<ArrowForwardIosRoundedIcon />}
                    >
                        <Grid
                            container
                            direction="column"
                            justifyContent="space-between"
                            alignItems="flex-start"
                        >
                            <Typography variant="h4">Do you feel like you need more support?</Typography>
                            <Spacer height={10} />
                            <Typography variant="caption">Talk to our recommended <b>coach for you!</b></Typography>
                        </Grid>
                    </Button>
                    <Spacer height={20} />

            </Stack></Stack>
        </Container>
    );
}

export default withAuthentication(Report);
