import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { AppBar, Backdrop, Button, Container, Grid, IconButton, Paper, Stack, Typography, Box } from '@mui/material';
import { default as React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { am_logEvent } from '../../app/amplitude';
import { fs_updateProperty, fs_updateSession, fs_updateUserProperty } from '../../app/firestore';
import { ai_getSessionSummary } from '../../app/openai';
import Spacer from '../common/Spacer';
import LoadingScreen from '../dialog/LoadingScreen';
import { getSession, getUser, setSession, setSessionProperty, setUserProperty } from '../user/UserSlice';
import SessionRating from './SessionRating';
import dayjs from 'dayjs';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import ATCBEventsObserver from '../olivya/ATCBEventsObserver';
import Lottie from 'react-lottie';
import confettiAnimation from '../../animations/confetti_animation.json';
import ActionCheckBox from '../olivya/ActionCheckBox';
import YTVideo from '../common/YTVideo';

function SessionCompleted(props)
{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const session = useSelector(getSession);

    const [openBackdrop, setOpenBackdrop] = React.useState(false);
    const [sessionSummaryText, setSessionSummaryText] = useState('');
    const [YTVideoURL, set_YTVideoURL] = useState('https://www.youtube.com/watch?v=TBl7In0GW8Y');
    
    useEffect(() =>
    {
        window.scrollTo(0, 0);

        if (session.status !== 'completed')
        {
            setOpenBackdrop(true);
            ai_getSessionSummary(session.messages, onGetSessionSummary);
        }
        if (session.status === 'completed')
        {
            setSessionSummaryText(session.long_summary);
            setYTVideo(session.rating);
        }

        // cleanup function
        return () =>
        {
        };
    }, []);

    const onQuitToMainMenu = () =>
    {
        navigate("/", { replace: true });
    }

    const onGetSessionSummary = result => 
    {
        const sessionSummary = JSON.parse(result.summary);
        sessionSummary.status = 'completed';
        sessionSummary.finished = dayjs().format();
        sessionSummary.length_seconds = Math.floor(dayjs().diff(dayjs(session.created)) / 1000);
        // Convert actions to an object based array
        if (sessionSummary.actions !== undefined && sessionSummary.actions.length > 0)
        {
            let oldActions = sessionSummary.actions;
            let tempActions = [];
            for (var i = 0; i < oldActions.length; i++)
            {
                tempActions.push({ title: oldActions[i], isChecked: false });
            }
            sessionSummary.actions = tempActions;
        }

        fs_updateSession(session.id, sessionSummary, onSummarySaved);
        fs_updateUserProperty('completed_sessions', user.completed_sessions + 1, () => { });
        dispatch(setUserProperty({ property: 'completed_sessions', value: user.completed_sessions + 1 }));
        
        let newSession = { ...session, ...sessionSummary };
        dispatch(setSession(newSession));

        am_logEvent('level_end', {
            level_name: user.completed_sessions + 1,
            success: true,
            length_seconds: sessionSummary.length_seconds
        });
    };

    const onSummarySaved = () =>
    {
        setOpenBackdrop(false);
    }

    const onChangeRating = (value) =>
    {
        // If deselected, set to default 0
        if (value === null) value = 0;

        fs_updateProperty(session.id, 'rating', value, onSummarySaved);
        setYTVideo(value);
    }

    const setYTVideo = value =>
    {
        // If deselected, set to default 0
        if (value === null) value = 0;

        // Negative video message
        if (value === 1 || value === 2)
            set_YTVideoURL('https://www.youtube.com/watch?v=WQU83HyTbBo');

        // Neutral video message
        if (value === 0 || value === 3)
            set_YTVideoURL('https://www.youtube.com/watch?v=QltSvf5Ehd0');

        // Positive video message
        if (value === 4 || value === 5)
            set_YTVideoURL('https://www.youtube.com/watch?v=8jQxUw9b2fs');
    }

    const onActionsChange = (sessionId, index, isChecked) =>
    {
        let oldActions = structuredClone(session.actions);
        //if actions are an array of strings, an old format.. then convert it to an array of objects!
        if (oldActions[0].isChecked === undefined)
        {
            let tempActions = [];
            for (var i = 0; i < oldActions.length; i++)
            {
                if (i === index)
                    tempActions.push({ title: oldActions[i], isChecked: true });
                else
                    tempActions.push({ title: oldActions[i], isChecked: false });
            }
            oldActions = tempActions;
        }

        oldActions[index].isChecked = isChecked;
        //update session in firestore
        fs_updateProperty(sessionId, 'actions', oldActions, () => { });
        dispatch(setSessionProperty({ property: 'actions', value: oldActions }));
    }

    const renderActions = () =>
    {
        if (session.actions !== undefined && session.actions.length > 0)
        {
            let oldActions = session.actions;
            //if actions are an array of strings, an old format.. then convert it to an array of objects!
            if (oldActions[0].isChecked === undefined)
            {
                let tempActions = [];
                for (var i = 0; i < oldActions.length; i++)
                {
                    tempActions.push({ title: oldActions[i], isChecked: false });
                }
                oldActions = tempActions;
            }

            let newActions = [];
            for (var i = 0; i < oldActions.length; i++)
            {
                newActions.push(<ActionCheckBox
                    key={i + '_actioncheckbox2'}
                    size='large'
                    label={oldActions[i].title}
                    sessionId={session.id}
                    index={i}
                    isChecked={oldActions[i].isChecked}
                    onClick={onActionsChange} />);
            }

            return newActions;
        }
        else
            return null;
    }

    const generateActionsString = () =>
    {
        let output = '';
        for (var i = 0; i < session.actions.length; i++)
        {
            output += session.actions[i].title + "\n";
        }

        return output;
    }

    const generateSummaryString = () =>
    {
        return session.calendar_summary !== undefined ? 'Summary: ' + session.calendar_summary : '';
    }

    return (
        <Container disableGutters maxWidth="sm">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackdrop}
            >
                <LoadingScreen />
            </Backdrop>
            <AppBar
                position='relative'
                variant='blurry'
            >
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <IconButton onClick={onQuitToMainMenu}>
                        <ArrowBackIosNewRoundedIcon />
                    </IconButton>
                    <Grid item justifyContent="center" alignItems="center">
                        <Typography variant="h2" color="text.lightGreen">Session Completed!</Typography>
                    </Grid>
                    <Spacer />
                </Grid>
            </AppBar>
            <Stack sx={{ width: '100%', alignItems: 'center' }}>
                <Box sx={{ position: 'absolute' }}>
                    <Lottie
                        isClickToPauseDisabled={true}
                        options={{
                            loop: false,
                            autoplay: true,
                            animationData: confettiAnimation,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice"
                            }
                        }}

                    /></Box>
                <Stack sx={{ width: '85%' }} spacing={5}>
                    <Paper variant="olivyaMessage">
                        <Typography variant="h4">How was Olivya in this session?</Typography>
                        <Spacer height={10} />
                        <SessionRating onChangeValue={onChangeRating} value={session.rating} />
                    </Paper>
                    {session.goals !== undefined && session.goals.length > 0 && <Stack spacing={1}>
                        <Typography variant="h2">My Goals:</Typography>
                        <Typography variant="long_session_summary">{session.goals.map((element, index) =>
                        {
                            return '- ' + element + '\n';
                        })}</Typography>
                    </Stack>}
                    {session.actions !== undefined && session.actions.length > 0 &&
                        <Stack spacing={1}>
                            <Typography variant="h2">My Actions:</Typography>
                            <Spacer height={5} />
                            {renderActions()}
                            {session.when !== undefined && session.actions !== undefined && session.actions.length > 0 &&
                                <AddToCalendarButton
                                    id='atcb-session'
                                    name={session.calendar_title !== undefined ? session.calendar_title : session.short_summary}
                                    description={'Goals: ' + session.goals.join('. ') + "\n\n" + 'Actions: ' + generateActionsString() + "\n\n" + generateSummaryString() }
                                    startDate={dayjs(session.when).format('YYYY-MM-DD')}
                                    startTime={dayjs(session.when).format('HH:mm')}
                                    endTime={dayjs(session.when).add(60, 'minute').format('HH:mm')}
                                    timezone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                                    organizator="Olivya|talk@olivya.ai"
                                    location="https://talk.olivya.ai"
                                    options="'Apple','Google','MicrosoftTeams','iCal'"
                                    buttonStyle="round"
                                    hideBackground="false"
                                    trigger="click"
                                    label="Add a reminder to your calendar"
                                    lightMode="dark"
                                    styleDark="--btn-font-weight: 300;--font: Montserrat;--btn-border: #00000040;--btn-border-radius: 15px;--btn-background: #00000040;--btn-shadow: rgba(0 0 0 / 40%) 0.3em 0.3em 0.6em;"
                                    size="6"
                                >
                                </AddToCalendarButton>}
                            <ATCBEventsObserver buttonId='atcb-session' onSuccess={() => am_logEvent('add_calendar', { source: 'session' })} />
                        </Stack>}
                    <Stack spacing={2}>
                        <Typography variant="h2">Session Summary:</Typography>
                        <Typography variant="long_session_summary">{sessionSummaryText}</Typography>
                        {session.emotions !== undefined && session.emotions.length > 0 && <Typography variant="long_session_summary">I was feeling <b>{session.emotions.join(', ')}</b>.</Typography>}
                    </Stack>
                    <Stack spacing={0}>
                        <Typography variant="h2">A Word from Olivya CEO</Typography>
                        <YTVideo 
                            videoURL={YTVideoURL}
                        />
                        <Spacer height={10} />
                        <Button
                            onClick={() => window.open("https://olivya.ai/coaches/", "_blank")}
                            variant="bright"
                            endIcon={<ArrowForwardIosRoundedIcon />}>
                            BOOK A COACH
                        </Button>
                    </Stack>
                    <Spacer height={1} />

                </Stack></Stack>
        </Container>
    );
}

export default SessionCompleted;
