import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { AppBar, Avatar, Container, Grid, IconButton, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { default as React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Spacer from '../common/Spacer';
import InstallPWAButton from './InstallPWAButton';
import { getUser } from '../user/UserSlice';
import { am_logEvent } from '../../app/amplitude';

function TutorialInstallPWA(props)
{
    const navigate = useNavigate();
    
    const [isBrowser, set_isBrowser] = useState(true);
    const [isIos, set_isIos] = useState(false);
    const [isMacSafari, set_isMac] = useState(false);
    const [isInstalled, set_isInstalled] = useState(true);

    useEffect(() =>
    {
        window.scrollTo(0, 0);

        const _isBrowser = checkIsBrowser();
        const _isIos = checkIsIos();
        const _isMac = checkIsMacSafari();

        am_logEvent('tutorial_install_open', { isBrowser: _isBrowser, isIos: _isIos, isMac: _isMac });

        set_isBrowser(_isBrowser);
        set_isIos(_isIos);
        set_isMac(_isMac);
        set_isInstalled(checkIsInstalled());

        // cleanup function
        return () => { };
    }, []);

    const checkIsBrowser = () =>
    {
        let displayMode = 'browser';
        const mqStandAlone = '(display-mode: standalone)';
        if (navigator.standalone || window.matchMedia(mqStandAlone).matches)
            displayMode = 'standalone';
            
        return displayMode === 'standalone' ? false : true;
    }

    // Function to check if the device is iOS
    const checkIsIos = () =>
    {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test(userAgent);
    };

    // Function to check if the browser is Safari on macOS
    const checkIsMacSafari = () =>
    {
        const ua = window.navigator.userAgent;
        return ua.includes('Macintosh') && ua.includes('Safari') && !ua.includes('Chrome');
    };

    // Chrome specific detection if the app is already installed
    const checkIsInstalled = () =>
    {
        // Check if the deferredPrompt is already stored globally
        if (window.deferredPrompt)
            return false;
        else
            return true;
    }

    return (
        <Container disableGutters maxWidth="sm">
            <AppBar
                position="relative"
                variant='blurry'
            >
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <IconButton onClick={() => navigate("/", { replace: true })}>
                        <ArrowBackIosNewRoundedIcon />
                    </IconButton>
                    <Grid item justifyContent="center" alignItems="center">
                        <Typography variant="h3" color="text.primary">How to Install Olivya?</Typography>
                    </Grid>
                    <Spacer width={50} />
                </Grid>
                
                
            </AppBar>
            <Stack sx={{ width: '100%', alignItems: 'center' }}>
                <Stack sx={{ width: '85%', alignItems: 'center' }} spacing={0} >
                    <Spacer height={40} />
                    {isInstalled && !isIos && !isMacSafari && <Typography variant="h4">You are all set! Start Olivya app from your home screen!</Typography>}
                    {isBrowser && !isIos && !isMacSafari && !isInstalled && <Stack sx={{ alignItems: 'center' }} spacing={0} >
                        <Typography variant="h6">Simply tap on the button bellow and follow your system prompt.</Typography>
                        <Spacer height={20} />
                        <InstallPWAButton />
                        </Stack>
                    }
                    {isBrowser && isIos && !isMacSafari && <Stack sx={{ alignItems: 'center' }} spacing={0} >
                        <Typography variant="h6">Simply tap on the <b>"Share"</b> button in your browser, and choose the <b>"Add to Home Screen"</b> option from the menu!</Typography>
                        <Spacer height={20} />
                        <Typography variant="h6">Using Safari:</Typography>
                        <img src='https://talk.olivya.ai/img/Tutorial_IosSafari.jpg' alt='tutorial for ios safari' style={{ width: '80%', height: 'auto' }} />
                        <Spacer height={20} />
                        <Typography variant="h6">Using Chrome:</Typography>
                        <img src='https://talk.olivya.ai/img/Tutorial_IosChrome.jpg' alt='tutorial for ios chrome' style={{ width: '80%', height: 'auto' }} />
                        <Spacer height={20} />
                        <Typography variant="h6">Tap on the <b>"Add to Home Screen"</b> option:</Typography>
                        <img src='https://talk.olivya.ai/img/Tutorial_IosAddToHome.jpg' alt='tutorial for add to home' style={{ width: '80%', height: 'auto' }} />
                    </Stack>
                    }
                    {isBrowser && isMacSafari && !isIos && <Stack sx={{ alignItems: 'center' }} spacing={0} >
                        <Typography variant="h6">If you are on a Mac and using Safari browser, simply click on the <b>"Share"</b> button and choose the <b>"Add to Dock"</b> option!</Typography>
                        <Spacer height={20} />
                        <img src='https://talk.olivya.ai/img/Tutorial_MacSafari.jpg' alt='tutorial for mac safari' style={{ width: '100%', height: 'auto' }} />
                        <Spacer height={60} />
                        <Typography variant="h6">If you are on an iPhone or iPad, simply tap on the <b>"Share"</b> button in your browser, and choose the <b>"Add to Home Screen"</b> option from the menu!</Typography>
                        <Spacer height={20} />
                        <Typography variant="h6">Using Safari:</Typography>
                        <img src='https://talk.olivya.ai/img/Tutorial_IosSafari.jpg' alt='tutorial for ios safari' style={{ width: '80%', height: 'auto' }} />
                        <Spacer height={20} />
                        <Typography variant="h6">Using Chrome:</Typography>
                        <img src='https://talk.olivya.ai/img/Tutorial_IosChrome.jpg' alt='tutorial for ios chrome' style={{ width: '80%', height: 'auto' }} />
                        <Spacer height={20} />
                        <Typography variant="h6">Tap on the <b>"Add to Home Screen"</b> option:</Typography>
                        <img src='https://talk.olivya.ai/img/Tutorial_IosAddToHome.jpg' alt='tutorial for add to home' style={{ width: '80%', height: 'auto' }} />
                    </Stack>
                    }
                    <Spacer height={80} />
                    <Typography variant="account_stat">Rise and Play ©️ 2024 – All Rights Reserved</Typography>
                    <Spacer height={40} />
                </Stack></Stack>
        </Container>
    );
}

export default TutorialInstallPWA;
