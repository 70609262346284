export const SESSION_SECONDS = 1800; //30min
export const EXTRA_SESSION_COST = 5; //Tokens
export const EXTEND_SESSION_COST = 2; //Tokens
export const EXTEND_SESSION_SECONDS = 300; //5min
export const FREE_TRIAL_DAYS = 30; // Days
export const STARS_1 = 7 * 60; // 7min
export const STARS_2 = 14 * 60; // 14min
export const STARS_3 = 21 * 60; // 21min
export const ROLES = { GUEST: 'guest', SUBSCRIBER: 'subscriber', ADMIN: 'admin' };
export const EMPTY_SESSION = 'I didn\'t talk much in this session';
export const VERSION = 'v2409194';


export const BACKEND_URL = 'https://talk.olivya.ai/phpbe/';
//export const BACKEND_URL = 'https://staging.olivya.ai/phpbe/';

//export const APP_URL = 'http://localhost:3000/';
export const APP_URL = 'https://talk.olivya.ai/';
//export const APP_URL = 'https://staging.olivya.ai/';

// SANDBOX PAYPAL
//export const PAYPAL_CLIENT_ID = 'AfEghNv3Kjl4YS7ILbz235kBd3tzFf-Z3ouo0h8_kSTvRcZaIGpdyBWnWZ0R2w3jKXh1prO7FxpJmEQt';
//export const PAYPAL_SUBSCRIPTION_PLAN_ID = 'P-8VV68586KY713770LMZ7MRUI';
// LIVE PAYPAL
export const PAYPAL_CLIENT_ID = 'AXxbJvXLxLu3Llqj16yDn-rfrqPvJ8yv0cMSHrDXscSmu6BR0IPK-l3RrIbYmLGODOmLzA0AEFWP_vpM';
export const PAYPAL_SUBSCRIPTION_PLAN_ID = 'P-3EP88884R7479880KMZ6Y5GY';

